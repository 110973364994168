$blue-color: #162459;
$grey-text-color: #7c7c7c;
$background-color-light: #d9d9d9;
$background-color-dark: #898989;
$background-color: #cbcbcb;
$background-white-color: #ffffff;
$background-green-color: #1eab4f;
$yellow-color: #ffc20e;
$background-blue-color: #38436d;
$font-black-color: #000;
$font-white-color: #fff;
.text-blue-color {
    color: $blue-color;
}
.font-size-m {
    font-size: 14px !important;
}
.text-white-color {
    color: #ffffff;
}
@font-face {
    font-family: "DINPro";
    font-style: normal;
    font-weight: normal;
    src: url(assets/fonts/DINPro.otf) format("opentype");
}
@font-face {
    font-family: "DINPro-Cond";
    font-style: normal;
    font-weight: normal;
    src: url(assets/fonts/DINPro-Cond.otf) format("opentype");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "DINPro";
}
body {
    scrollbar-width: none;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh !important;
    margin: 0;
}

input,
select,
textarea,
button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
a{
    text-decoration: none !important;
    color: black;
}

@media (max-height: 428px) and (orientation: landscape) {
    body {
        position: relative;
        height: 100vh;
    }
    body:after {
        position: absolute;
        content: "This app only supports portrait orientation. Please rotate your phone to continue";
        top: 0;
        left: 0;
        right: 0;
        font-size: 22px;
        height: 100vh;
        width: 100vw;
        line-height: 100vh;
        word-wrap: break-word;
        text-align: center;
    }
}

.font-din-pro {
    font-family: "DINPro";
}
.font-din-pro-cond {
    font-family: "DINPro-Cond";
}

.big-button {
    height: 52px;
    line-height: 52px;
    width: 100%;
    border-radius: 25px !important;
    text-transform: uppercase;
    background-color: $blue-color !important;
    font-size: 18px;
    color: $font-white-color !important;
    cursor: pointer !important;
    border: none;
}

.big-button:disabled {
    background-color: #9b9b9b !important;
    color: #fff !important;
    cursor: not-allowed !important;
}
html,
body {
    overflow: -moz-scrollbars-none;
    height: 100%;
}
body::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* Disable scrollbar Chrome/Safari/Webkit */
}
.fflag-CH,
.fflag-NP {
    box-shadow: none !important;
}
.fflag-DZ {
    background-position: center 0.2287%;
}
.fflag-AO {
    background-position: center 0.4524%;
}
.fflag-BJ {
    background-position: center 0.6721%;
}
.fflag-BW {
    background-position: center 0.8958%;
}
.fflag-BF {
    background-position: center 1.1162%;
}
.fflag-BI {
    background-position: center 1.3379%;
}
.fflag-CM {
    background-position: center 1.5589%;
}
.fflag-CV {
    background-position: center 1.7836%;
}
.fflag-CF {
    background-position: center 2.0047%;
}
.fflag-TD {
    background-position: center 2.2247%;
}
.fflag-CD {
    background-position: left 2.4467%;
}
.fflag-DJ {
    background-position: left 2.6674%;
}
.fflag-EG {
    background-position: center 2.8931%;
}
.fflag-GQ {
    background-position: center 3.1125%;
}
.fflag-ER {
    background-position: left 3.3325%;
}
.fflag-ET {
    background-position: center 3.5542%;
}
.fflag-GA {
    background-position: center 3.7759%;
}
.fflag-GM {
    background-position: center 4.0015%;
}
.fflag-GH {
    background-position: center 4.2229%;
}
.fflag-GN {
    background-position: center 4.441%;
}
.fflag-GW {
    background-position: left 4.66663%;
}
.fflag-CI {
    background-position: center 4.8844%;
}
.fflag-KE {
    background-position: center 5.1061%;
}
.fflag-LS {
    background-position: center 5.3278%;
}
.fflag-LR {
    background-position: left 5.5495%;
}
.fflag-LY {
    background-position: center 5.7712%;
}
.fflag-MG {
    background-position: center 5.994%;
}
.fflag-MW {
    background-position: center 6.2156%;
}
.fflag-ML {
    background-position: center 6.4363%;
}
.fflag-MR {
    background-position: center 6.658%;
}
.fflag-MU {
    background-position: center 6.8805%;
}
.fflag-YT {
    background-position: center 7.1038%;
}
.fflag-MA {
    background-position: center 7.3231%;
}
.fflag-MZ {
    background-position: left 7.5448%;
}
.fflag-NA {
    background-position: left 7.7661%;
}
.fflag-NE {
    background-position: center 7.98937%;
}
.fflag-NG {
    background-position: center 8.2099%;
}
.fflag-CG {
    background-position: center 8.4316%;
}
.fflag-RE {
    background-position: center 8.6533%;
}
.fflag-RW {
    background-position: right 8.875%;
}
.fflag-SH {
    background-position: center 9.0967%;
}
.fflag-ST {
    background-position: center 9.32237%;
}
.fflag-SN {
    background-position: center 9.5426%;
}
.fflag-SC {
    background-position: left 9.7628%;
}
.fflag-SL {
    background-position: center 9.9845%;
}
.fflag-SO {
    background-position: center 10.2052%;
}
.fflag-ZA {
    background-position: left 10.4269%;
}
.fflag-SS {
    background-position: left 10.6486%;
}
.fflag-SD {
    background-position: center 10.8703%;
}
.fflag-SR {
    background-position: center 11.0945%;
}
.fflag-SZ {
    background-position: center 11.3135%;
}
.fflag-TG {
    background-position: left 11.5354%;
}
.fflag-TN {
    background-position: center 11.7593%;
}
.fflag-UG {
    background-position: center 11.9799%;
}
.fflag-TZ {
    background-position: center 12.2005%;
}
.fflag-EH {
    background-position: center 12.4222%;
}
.fflag-YE {
    background-position: center 12.644%;
}
.fflag-ZM {
    background-position: center 12.8664%;
}
.fflag-ZW {
    background-position: left 13.0873%;
}
.fflag-AI {
    background-position: center 13.309%;
}
.fflag-AG {
    background-position: center 13.5307%;
}
.fflag-AR {
    background-position: center 13.7524%;
}
.fflag-AW {
    background-position: left 13.9741%;
}
.fflag-BS {
    background-position: left 14.1958%;
}
.fflag-BB {
    background-position: center 14.4175%;
}
.fflag-BQ {
    background-position: center 14.6415%;
}
.fflag-BZ {
    background-position: center 14.8609%;
}
.fflag-BM {
    background-position: center 15.0826%;
}
.fflag-BO {
    background-position: center 15.306%;
}
.fflag-VG {
    background-position: center 15.528%;
}
.fflag-BR {
    background-position: center 15.7496%;
}
.fflag-CA {
    background-position: center 15.9694%;
}
.fflag-KY {
    background-position: center 16.1911%;
}
.fflag-CL {
    background-position: left 16.4128%;
}
.fflag-CO {
    background-position: left 16.6345%;
}
.fflag-KM {
    background-position: center 16.8562%;
}
.fflag-CR {
    background-position: center 17.0779%;
}
.fflag-CU {
    background-position: left 17.2996%;
}
.fflag-CW {
    background-position: center 17.5213%;
}
.fflag-DM {
    background-position: center 17.743%;
}
.fflag-DO {
    background-position: center 17.968%;
}
.fflag-EC {
    background-position: center 18.1864%;
}
.fflag-SV {
    background-position: center 18.4081%;
}
.fflag-FK {
    background-position: center 18.6298%;
}
.fflag-GF {
    background-position: center 18.8515%;
}
.fflag-GL {
    background-position: left 19.0732%;
}
.fflag-GD {
    background-position: center 19.2987%;
}
.fflag-GP {
    background-position: center 19.518%;
}
.fflag-GT {
    background-position: center 19.7383%;
}
.fflag-GY {
    background-position: center 19.96%;
}
.fflag-HT {
    background-position: center 20.1817%;
}
.fflag-HN {
    background-position: center 20.4034%;
}
.fflag-JM {
    background-position: center 20.6241%;
}
.fflag-MQ {
    background-position: center 20.8468%;
}
.fflag-MX {
    background-position: center 21.0685%;
}
.fflag-MS {
    background-position: center 21.2902%;
}
.fflag-NI {
    background-position: center 21.5119%;
}
.fflag-PA {
    background-position: center 21.7336%;
}
.fflag-PY {
    background-position: center 21.9553%;
}
.fflag-PE {
    background-position: center 22.177%;
}
.fflag-PR {
    background-position: left 22.4002%;
}
.fflag-BL {
    background-position: center 22.6204%;
}
.fflag-KN {
    background-position: center 22.8421%;
}
.fflag-LC {
    background-position: center 23.0638%;
}
.fflag-PM {
    background-position: center 23.2855%;
}
.fflag-VC {
    background-position: center 23.5072%;
}
.fflag-SX {
    background-position: left 23.732%;
}
.fflag-TT {
    background-position: center 23.9506%;
}
.fflag-TC {
    background-position: center 24.1723%;
}
.fflag-US {
    background-position: center 24.394%;
}
.fflag-VI {
    background-position: center 24.6157%;
}
.fflag-UY {
    background-position: left 24.8374%;
}
.fflag-VE {
    background-position: center 25.0591%;
}
.fflag-AF {
    background-position: center 25.2808%;
}
.fflag-AZ {
    background-position: center 25.5025%;
}
.fflag-BD {
    background-position: center 25.7242%;
}
.fflag-BT {
    background-position: center 25.9459%;
}
.fflag-BN {
    background-position: center 26.1676%;
}
.fflag-KH {
    background-position: center 26.3885%;
}
.fflag-CN {
    background-position: left 26.611%;
}
.fflag-GE {
    background-position: center 26.8327%;
}
.fflag-HK {
    background-position: center 27.0544%;
}
.fflag-IN {
    background-position: center 27.2761%;
}
.fflag-ID {
    background-position: center 27.4978%;
}
.fflag-JP {
    background-position: center 27.7195%;
}
.fflag-KZ {
    background-position: center 27.9412%;
}
.fflag-LA {
    background-position: center 28.1615%;
}
.fflag-MO {
    background-position: center 28.3846%;
}
.fflag-MY {
    background-position: center 28.6063%;
}
.fflag-MV {
    background-position: center 28.829%;
}
.fflag-MN {
    background-position: left 29.0497%;
}
.fflag-MM {
    background-position: center 29.2714%;
}
.fflag-NP {
    background-position: left 29.4931%;
}
.fflag-KP {
    background-position: left 29.7148%;
}
.fflag-MP {
    background-position: center 29.9365%;
}
.fflag-PW {
    background-position: center 30.1582%;
}
.fflag-PG {
    background-position: center 30.3799%;
}
.fflag-PH {
    background-position: left 30.6016%;
}
.fflag-SG {
    background-position: left 30.8233%;
}
.fflag-KR {
    background-position: center 31.045%;
}
.fflag-LK {
    background-position: right 31.2667%;
}
.fflag-TW {
    background-position: left 31.4884%;
}
.fflag-TJ {
    background-position: center 31.7101%;
}
.fflag-TH {
    background-position: center 31.9318%;
}
.fflag-TL {
    background-position: left 32.1535%;
}
.fflag-TM {
    background-position: center 32.3752%;
}
.fflag-VN {
    background-position: center 32.5969%;
}
.fflag-AL {
    background-position: center 32.8186%;
}
.fflag-AD {
    background-position: center 33.0403%;
}
.fflag-AM {
    background-position: center 33.258%;
}
.fflag-AT {
    background-position: center 33.4837%;
}
.fflag-BY {
    background-position: left 33.7054%;
}
.fflag-BE {
    background-position: center 33.9271%;
}
.fflag-BA {
    background-position: center 34.1488%;
}
.fflag-BG {
    background-position: center 34.3705%;
}
.fflag-HR {
    background-position: center 34.5922%;
}
.fflag-CY {
    background-position: center 34.8139%;
}
.fflag-CZ {
    background-position: left 35.0356%;
}
.fflag-DK {
    background-position: center 35.2555%;
}
.fflag-EE {
    background-position: center 35.479%;
}
.fflag-FO {
    background-position: center 35.7007%;
}
.fflag-FI {
    background-position: center 35.9224%;
}
.fflag-FR {
    background-position: center 36.1441%;
}
.fflag-DE {
    background-position: center 36.3658%;
}
.fflag-GI {
    background-position: center 36.5875%;
}
.fflag-GR {
    background-position: left 36.8092%;
}
.fflag-HU {
    background-position: center 37.0309%;
}
.fflag-IS {
    background-position: center 37.2526%;
}
.fflag-IE {
    background-position: center 37.4743%;
}
.fflag-IM {
    background-position: center 37.696%;
}
.fflag-IT {
    background-position: center 37.9177%;
}
.fflag-JE {
    background-position: center 38.1394%;
}
.fflag-XK {
    background-position: center 38.3611%;
}
.fflag-LV {
    background-position: center 38.5828%;
}
.fflag-LI {
    background-position: left 38.8045%;
}
.fflag-LT {
    background-position: center 39.0262%;
}
.fflag-LU {
    background-position: center 39.2479%;
}
.fflag-MT {
    background-position: left 39.4696%;
}
.fflag-MD {
    background-position: center 39.6913%;
}
.fflag-MC {
    background-position: center 39.913%;
}
.fflag-ME {
    background-position: center 40.1347%;
}
.fflag-NL {
    background-position: center 40.3564%;
}
.fflag-MK {
    background-position: center 40.5781%;
}
.fflag-NO {
    background-position: center 40.7998%;
}
.fflag-PL {
    background-position: center 41.0215%;
}
.fflag-PT {
    background-position: center 41.2432%;
}
.fflag-RO {
    background-position: center 41.4649%;
}
.fflag-RU {
    background-position: center 41.6866%;
}
.fflag-SM {
    background-position: center 41.9083%;
}
.fflag-RS {
    background-position: center 42.13%;
}
.fflag-SK {
    background-position: center 42.3517%;
}
.fflag-SI {
    background-position: center 42.5734%;
}
.fflag-ES {
    background-position: left 42.7951%;
}
.fflag-SE {
    background-position: center 43.0168%;
}
.fflag-CH {
    background-position: center 43.2385%;
}
.fflag-TR {
    background-position: center 43.4602%;
}
.fflag-UA {
    background-position: center 43.6819%;
}
.fflag-GB {
    background-position: center 43.9036%;
}
.fflag-VA {
    background-position: right 44.1253%;
}
.fflag-BH {
    background-position: center 44.347%;
}
.fflag-IR {
    background-position: center 44.5687%;
}
.fflag-IQ {
    background-position: center 44.7904%;
}
.fflag-IL {
    background-position: center 45.0121%;
}
.fflag-KW {
    background-position: left 45.2338%;
}
.fflag-JO {
    background-position: left 45.4555%;
}
.fflag-KG {
    background-position: center 45.6772%;
}
.fflag-LB {
    background-position: center 45.897%;
}
.fflag-OM {
    background-position: left 46.1206%;
}
.fflag-PK {
    background-position: center 46.3423%;
}
.fflag-PS {
    background-position: center 46.561%;
}
.fflag-QA {
    background-position: center 46.7857%;
}
.fflag-SA {
    background-position: center 47.0074%;
}
.fflag-SY {
    background-position: center 47.2291%;
}
.fflag-AE {
    background-position: center 47.4508%;
}
.fflag-UZ {
    background-position: left 47.6725%;
}
.fflag-AS {
    background-position: right 47.8942%;
}
.fflag-AU {
    background-position: center 48.1159%;
}
.fflag-CX {
    background-position: center 48.3376%;
}
.fflag-CC {
    background-position: center 48.5593%;
}
.fflag-CK {
    background-position: center 48.781%;
}
.fflag-FJ {
    background-position: center 49.0027%;
}
.fflag-PF {
    background-position: center 49.2244%;
}
.fflag-GU {
    background-position: center 49.4445%;
}
.fflag-KI {
    background-position: center 49.6678%;
}
.fflag-MH {
    background-position: left 49.8895%;
}
.fflag-FM {
    background-position: center 50.1112%;
}
.fflag-NC {
    background-position: center 50.3329%;
}
.fflag-NZ {
    background-position: center 50.5546%;
}
.fflag-NR {
    background-position: left 50.7763%;
}
.fflag-NU {
    background-position: center 50.998%;
}
.fflag-NF {
    background-position: center 51.2197%;
}
.fflag-WS {
    background-position: left 51.4414%;
}
.fflag-SB {
    background-position: left 51.6631%;
}
.fflag-TK {
    background-position: center 51.8848%;
}
.fflag-TO {
    background-position: left 52.1065%;
}
.fflag-TV {
    background-position: center 52.3282%;
}
.fflag-VU {
    background-position: left 52.5499%;
}
.fflag-WF {
    background-position: center 52.7716%;
}
.fflag-EU {
    background-position: center 52.9933%;
}
.fflag-JR {
    background-position: center 53.215%;
}
.fflag-OLY {
    background-position: center 53.4315%;
}
.fflag-UN {
    background-position: center 53.6584%;
}

.fflag.ff-sm {
    width: 18px;
    height: 11px;
}

.fflag.ff-md {
    width: 30px;
    height: 18px;
}

.fflag.ff-lg {
    width: 42px;
    height: 27px;
}

.fflag.ff-xl {
    width: 60px;
    height: 37px;
}

.fflag {
    background-image: url("./assets/images/flagSprite42.png");
    background-repeat: no-repeat;
    background-size: 100% 49494%;
    display: inline-block;
    overflow: hidden;
    position: relative;
    box-sizing: content-box;
    border: 1px solid lightgray;
    border-radius: 3px !important;
}
.grey {
    color: $background-color-dark;
    fill: $background-color-dark !important;
}
.green {
    color: #1eab4f;
    fill: #1eab4f !important;
}
.close-bg {
    background: red;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
}
.close-x {
    position: absolute;
    top: -16px;
    left: 6.5px;
    font-size: 19px;
    color: white;
    font-weight: bolder;
}
.close-bg:hover .close-x {
    color: #162459;
}
.close-bg:hover {
    background-color: white;
}
.close-btn:hover ~ .close-btn-bg {
    background-color: transparent;
}

.sub-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.container-wrapper {
    overflow: scroll;
    max-height: calc(100% - 172px);
}
.align-items-center {
    align-items: center;
}
.hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}
.text-overflow-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.box-header {
    background-color: $blue-color;
    color: #ffffff;
    line-height: 52px;
    border-radius: 25px 25px 0 0;
    padding: 0 20px;
    z-index: 10;
}
.box-container {
    border-radius: 25px;
    border: 1px solid $background-color-light !important;
}
.bid-log {
    max-height: calc(100% - 32px);
    height: calc(100% - 32px);
    overflow: hidden;
}
span.live {
    display: inline-block;
    min-height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: red;
    animation: live-indicator 1s alternate ease-in-out infinite;
    margin-right: 5px;
}
@keyframes live-indicator {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.add-cursor {
    cursor: pointer;
}
.bid-log-container {
    height: inherit;
    overflow-y: scroll;
    scrollbar-width: none;
    margin-bottom: 5px;
}
.bid-log-container::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
}

.bid-log-container::-webkit-scrollbar-thumb {
    background-color: lightgray;
    min-height: 25px;
    border-radius: 10px;
}
.bid-log-container > div:last-of-type > .mat-card {
    margin-bottom: 70px !important;
}

.live_bg {
    background-color: white;
    animation: bidLogEntryAnime 0.5s ease-in-out;
}
@keyframes bidLogEntryAnime {
    from {
        opacity: 0;
        max-height: 0px;
        padding-block: 0px;
    }
    to {
        opacity: 1;
        max-height: 150px;
        padding-block: 16px;
    }
}
.bid-price {
    background-color: #26a24f;
    color: white;
    height: max-content;
}
.card {
    font-size: 12px;
    margin: 10px;
    box-shadow: 3px 3px 5px lightgray;
}
.card .img {
    max-width: 33%;
    max-height: 81px;
}
.card img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
.card .img span {
    background-color: #26a24f;
    font-weight: bold;
    color: white;
    border-radius: 0 0 5px 5px;
}
.card .title {
    font-size: 15px;
}
.card .date {
    font-size: 13px;
    margin-block: 5px;
}
.card :is(button, a) {
    color: white;
    border: none;
    text-align: center;
    padding-block: 5px;
    border-radius: 5px;
}
.card a {
    background-color: #6acad1;
}
.card button {
    background-color: #6a7fcd;
}
.card .actions {
    gap: 24px;
    margin-top: 5px;
}
.auction-box-body {
    max-height: 300px;
    position: relative;
}
.bold {
    font-weight: bolder;
    font-size: 16px;
}
.bolder {
    font-weight: bolder;
 }
.previous-current-auction-btn {
    width: 90%;
    padding: 12px 12px;
    border-radius: 15px;
    margin-bottom: 15px;
    margin-inline: 17px;
    box-shadow: 5px 5px 10px grey;
    background-color: #fe7f01;
    color: white;
    border: none;
    cursor: pointer;
}
.container-fluid {
    max-width: 100%;
    height: 100%;
}
.regsiter-container{min-height: 100%; max-height: max-content;}
.footer-circle-icon .fa-right-from-bracket,
.fa-clipboard-list,
.fa-robot,
.fa-book,
.fa-bag-shopping,
.fa-dollar,
.fa-expand-alt {
    fill: #162459;
    width: 100%;
    height: 22px;
    border-radius: 50%;
}
app-bid-bot-new .fa-robot {
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    width: max-content;
    -moz-transform: translate(-45%,-51%);
}
.upcoming-close{
    width: 17px;
    fill: #162459;
}
.product-image {
    max-height: 230px;
    width: 100%;
    height: 100%;
    min-height: 200px;
    object-fit: contain;
}
.product-price {
    position: absolute;
    border: 1px solid $grey-text-color;
    padding: 5px 10px;
    border-radius: 15px;
    color: $grey-text-color;
    background-color: #ffffff;
    font-size: 18px;
}
.box-footer-buy-now {
    background-color: #7c7c7c;
    color: #ffffff;
    line-height: 25px;
    border-radius: 0 0 25px 25px;
    padding: 11px 15px;
    position: relative;
}
.instruction-box-body {
    font-size: 14px;
    background-color: #ffffff;
    max-height: inherit;
    padding-inline: 4px;
    padding-block: 20px;
    gap: 30px;
    overflow: scroll;
    border-radius: 0px 0px 23px 23px;
    scrollbar-width: none;
}
.instruction-box-body::-webkit-scrollbar {
    display: none;
}
.descirption {
    font-size: 12px;
}
.bid-play-rate-text:before {
    content: "";
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: -10px;
    border-right: 1px solid #ffffff;
    opacity: 0.5;
}
.bid-play-rate-text {
    position: absolute;
    top: 0px;
    right: 15px;
}
video#iphone-video {
    background-color: #000;
}
.auction-action-circle {
    height: 133px;
    aspect-ratio: 1;
    position: absolute;
    left: 50%;
    border-radius: 50%;
    top: 50%;
    cursor: pointer;
    text-decoration: none;
    transform: translate(-50%, -50%);
    z-index: 2;
}
.auction-action-circle span {
    font-size: 16px;
}
.auction-action-circle.auction-action-circle-not-started {
    background-color: #ffffff;
    text-decoration: none;
}
.auction-action-circle.auction-action-circle-not-started
    .auction-data-loader-column {
    margin: 16px;
}
.countdown-image {
    background-image: url(./assets/images/countdown-image-4.png);
    background-size: 1105% 100%;
    background-position-y: 0;
    overflow: hidden;
    background-color: #ffffff;
    padding: 45px 40px;
    line-height: 22px;
    text-align: center;
    font-size: 25px;
    width: 133px;
}
.box-footer {
    background-color: $blue-color;
    color: #ffffff;
    line-height: 52px;
    border-radius: 0 0 25px 25px;
    padding: 0 15px;
    position: relative;
}
.auction-bid-button {
    color: #ffffff;
    display: flex;
    align-items: center;
    width: calc(50% - 72px);
    line-height: 1.2;
    overflow: hidden;
    text-align: left;
}
.play-balance-capsule {
    min-height: 52px;
    max-height: 52px;
    border: 1px solid $blue-color;
    border-radius: 25px;
    overflow: hidden;
    background-color: #ffffff;
    margin-top: 50px;
}
.text-uppercase {
    text-transform: uppercase;
}
.buy-play-button-wrapper {
    height: 52px;
    background-color: $background-color;
    font-size: 15px;
    color: $blue-color;
    cursor: pointer;
}
.fa-calendar{width: 12px; height: 14px;}
.fa-external-link-alt, .fa-gavel, .fa-eye{ width: 12px; height: 12px}
.fa-eye, .fa-external-link-alt{fill: white;}
.fa-smile-beam{    
    width: 38px;
    height: 38px;
    fill: #162459;
}
.fa-sad-beam{    
    width: 38px;
    height: 38px;
    fill: red;
}
.w-50 {
    width: 50%;
}
.question-icon {
    color: #162459;
    width: 46px;
    text-align: center;
    margin-right: 3px;
    margin-block: 3px;
    text-decoration: none;
    // z-index: 99999999999;
}
.question-icon:hover {
    background-color: #162459;
    color: #ffffff;
    border: 1px solid #162459;
}
.h-20 {
    height: 45px;
}
.question-icon:hover .upcoming-close{
    fill: white;
}
.h-25{height: 25px;}
.slider {
    // height: 50%;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;
    overflow: hidden;
}
.slider::-webkit-scrollbar {
    display: none;
}
.slider > div {
    min-width: 100%;
    border: none !important;
    scroll-snap-align: center;
    position: relative;
}
.slider > div > a {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    height: 43px;
    width: 43px;
    border-radius: 50%;
    display: flex;
    font-size: 35px;
    align-items: center;
    justify-content: center;
    fill: #fff;
}
.margin-right-19{margin-right: 19px;}
.slider > div > video {
    pointer-events: none;
}
.slider > div > a.hidden {
    display: none;
}
.circle-icon {
    position: absolute;
    right: 0;
    height: 46px;
    width: 46px;
    text-align: center;
    line-height: 44px;
    font-size: 22px;
    background-color: #ffffff;
    color: $blue-color;
    border: 1px solid #162459;
    border-radius: 50%;
    z-index: 9999999;
}
.registration-form {
    width: 90%;
    border-radius: 0px !important;
    font-size: 18px;
    gap: 22px;
}
.registration-form input {
    border-radius: 0px !important;
}
.registration-form button {
    border-radius: 0px !important;
}
.t-72{top: 72px;}
.input-text {
    height: 52px;
    padding: 15px 12px;
    text-align: center;
    width: 100%;
    line-height: 52px;
    color: black;
    border-radius: 25px;
    border: 1px solid $grey-text-color;
    outline: none;
}
.flex-gap {
    gap: 13px;
}
.reg-forget-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    max-width: calc(100% - 30px);
    min-width: calc(100% - 30px);
}
.reg-forget-links span {
    text-align: center;
}
.error {
    font-size: 14px;
    color: red;
}
.country-input {
    position: relative;
}
.country-input > .fflag {
    position: absolute;
    top: 53%;
    left: 5%;
}
.country-input > .fflag ~ .input-text {
    padding-left: 60px !important;
}
.verify-number,
.verify-btn {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}
.verify-number {
    display: flex;
    gap: 24px;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
}
.partitioned > span {
    display: inline-block;
    border: 1px solid $grey-text-color;
    min-height: 40px;
    min-width: 50px;
}
.digit-box {
    border: none;
    border-bottom: 2px solid $grey-text-color;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.digit-box-error {
    border-color: red;
    animation: trigger 0.1s ease 8;
}
@keyframes trigger {
    33% {
        transform: translateX(3px);
    }
    100% {
        transform: translateX(-3px);
    }
}

.partitioned {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    outline: none;
    padding-inline: 17px;
    z-index: 99;
    border: none;
    letter-spacing: calc(4.5rem - 18px);
    color: transparent;
    caret-color: black;
}
.partitioned::selection {
    background-color: transparent;
}
.app-header {
    border-bottom: 1px solid $background-color-light;
    background-color: $blue-color;
    border-radius: 27px 27px 0 0;
    height: 180px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sm-app-header {
    padding: 10px 15px;
    padding-right: 0;
    background-color: $blue-color;
    border-radius: 24px 24px 0 0;
    height: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.change-header {
    flex-direction: column !important;
    width: 100%;
    line-height: 30px;
    justify-content: space-evenly;
}

.sm-change-header {
    flex-direction: row !important;
    width: 100%;
    line-height: 30px;
    justify-content: space-between;
}

.logo-container {
    max-width: 151px;
    width: 100%;
    position: relative;
}

.sm-logo-container {
    margin-top: 5px;
    max-width: 151px;
    width: 100%;
    position: relative;
}

.header-tag-line {
    color: $background-color-light;
}

.header-tag-line span {
    position: relative;
    text-transform: uppercase;
}
@media screen and (max-width: 480px) {
    .app-header {
        border-radius: 0 !important;
        position: sticky;
        top: 0;
        z-index: 99;
    }
    .sm-app-header {
        border-radius: 0 !important;
        position: sticky;
        top: 0;
        z-index: 99;
    }
}
.copy-right-container {
    background-color: #162459;
    color: white;
    font-size: 12px;
    padding: 20px;
    text-align: center;
    width: 100%;
    border-radius: 0px 0px 25px 25px;
}

@media screen and (max-width: 480px) {
    .copy-right-container {
        padding: 10px;
        text-align: center;
        border-radius: 0px;
    }
}

footer.app-footer {
    z-index: 99;
    gap: 10px;
    background-color: white;
    padding: 10px;
    height: 56px;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
}

.footer-circle-icon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 40px;
    background-color: white;
    border: 1px solid #162459;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.footer-circle-icon:hover .fa-solid,
.footer-circle-icon:hover,
.footer-circle-icon:hover .fa {
    background-color: #162459;
    fill: #ffffff;
}

.bot-active {
    background-color: #fe7f01;
    pointer-events: none;
    border-color: #fe7f01;
}
.bot-active svg {
    fill: white;
}
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
}
.mobile-frame {
    display: block;
    height: calc(100vh - 40px);
    max-width: 408px;
    max-height: 827px;
    aspect-ratio: 408/827;
    background-image: url("./assets/images/mobile-frame.png");
    position: relative;
    background-repeat: no-repeat;
    z-index: 99;
    padding: 8% 3% 8% 4.5% !important;
    pointer-events: fill;
    background-size: 100% 100%;
}

.app-container {
    position: relative;
    border-radius: 24px 24px 4vh 4vh;
    height: 100%;
    overflow: hidden;
    z-index: 9999999999999;
}

.app-container::-webkit-scrollbar {
    display: none;
}
@media screen and (max-width: 480px) {
    .mobile-frame {
        height: 100vh;
        max-height: 100vh;
        max-width: 100vw;
        background-image: none;
        padding: 0 !important;
        margin: 0;
        width: 100vw;
    }
    .app-container {
        border-radius: 0;
        margin: 0;
        width: 100% !important;
    }
}

@media (max-height: 428px) and (orientation: landscape) {
    .mobile-frame {
        display: none;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .mobile-frame {
        width: 408px;
        min-width: 408px;
    }
}
.upcoming-register {
    font-size: 14px;
    line-height: 36px;
    text-decoration: none;
    color: #000;
}

.rounded-circle-auctions {
    width: 43px;
    height: 43px;
    display: inline-block;
    text-align: center;
    line-height: 42px;
    background-color: #ffffff;
    border: 1px solid #162459;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;
    fill: #7c7c7c;
}
.rounded-circle-auctions:hover {
    background-color: #162459;
    fill: white;
    text-decoration: none;
}
.rounded-circle-auctions.hidden {
    fill: white;
    background-color: #c5c4c4;
    border-color: #c5c4c4;
    pointer-events: none;
}
.upcoming-auctions-box-footer {
    margin-bottom: 55px;
}
.ext-embed-layout .auction-action-circle {
    height: 100px;
    padding: 35%;
    line-height: 15px;
    font-size: 15px;
    position: unset;
    margin: 5px 0;
    display: block;
    transform: none;
}
.logout-yes-btn {
    height: 45px;
    line-height: 45px;
    width: 100%;
    border-radius: 25px !important;
    text-transform: uppercase;
    border: 1px solid $blue-color;
    font-size: 16px;
    background-color: $blue-color;
    color: $background-color-light;
}
.logout-no-btn {
    height: 45px;
    line-height: 45px;
    width: 100%;
    border-radius: 25px !important;
    text-transform: uppercase;
    border: 1px solid $blue-color;
    font-size: 16px;
    background-color: $background-color-light;
    color: $blue-color;
}
.bid-button-disabled {
    cursor: not-allowed;
}
.countdown-image span.counter-number {
    font-size: 40px;
    position: relative;
    line-height: 40px;
}
.play-buy-form-input {
    display: block;
    position: relative;
    width: 100%;
    text-align: left;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    letter-spacing: normal;
    padding: 15px;
    background-color: $font-white-color;
    border-radius: 25px;
    border: 1px solid $blue-color;
    margin-top: 20px;
}
.play-buy-form-input:last-child {
    margin-bottom: 0;
}
.play-buy-form-input select {
    border: none;
    outline: none;
}
.play-buy-form-input select::-ms-expand {
    -moz-appearance: none;
    -webkit-appearance: none;
    display: none;
}
.w-75 {
    width: 75px !important;
    font-size: 18px;
}
input.play-form-input {
    border: none;
    width: calc(100% - 70px);
    padding-left: 12px;
    font-size: 18px;
}
label.play-form-label {
    padding-right: 10px;
    border-right: 1px solid $blue-color;
    color: $blue-color;
    font-size: 18px;
}
.border-right-none {
    border-right: none !important;
}
.wallet-icon.solflare-icon {
    background-position: -46px 2px;
}
.wallet-icon.sollet-icon {
    background-position: -94px 2px;
}
.wallet-icon.wallet-icon.sollet-icon:hover {
    background-position: -94px -46px;
}
.wallet-icon.big-icon {
    background-position: -142px 2px;
}
.wallet-icon.wallet-icon.big-icon:hover {
    background-position: -142px -46px;
}
.wallet-icon:hover {
    border: 1px solid $blue-color;
}
.wallet-icon:hover .wallet-icon {
    fill: $font-white-color;
}
.mateamask-icon:hover,
.trust-wallet-icon:hover {
    background-color: #162459;
}
.wallet-icon {
    background-image: url("./assets/images/icons.png");
    background-size: 187px;
    position: relative;
    background-repeat: no-repeat !important;
    width: 40px;
    height: 40px;
    text-align: center;
    display: inline-block;
    line-height: 40px;
    background-color: #fff;
    border: 1px solid #b2b2b2;
    border-radius: 50%;
    margin: 0 10px;
    cursor: pointer;
}
.trust-wallet-icon,
.mateamask-icon {
    background-color: #7c7c7c;
    background-size: 30px;
    position: relative;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    text-align: center;
    background-position: 8px 8px;
}

.wallet-icon-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    width: 100%;
    align-items: center;
    align-content: center;
    margin-top: 20px;
}
.wallet-icon.wallet-icon.solflare-icon:hover {
    background-position: -46px -46px;
}
.wallet-icon.phantom-icon {
    background-position: 2px 2px;
}
.wallet-icon.wallet-icon.phantom-icon:hover {
    background-position: 2px -46px;
}
.wallet-name {
    text-transform: uppercase;
    color: $blue-color;
    font-weight: 600;
    font-size: 10px;
}

.btc-wallet-address button {
    background-color: transparent;
    border: none;
}
label.btc-form-label {
    color: $blue-color;
    font-size: 16px;
}
.btc-form-label {
    width: 85%;
    border: none;
    padding: 2px;
}

.btc-wallet-address {
    display: flex;
    justify-content: space-between;
}
.mateamask-icon {
    background: url("./assets/images/metamask.png");
    border: 1px solid #b2b2b2;
    border-radius: 50%;
    margin: 2px;
    background-size: 32px;
    background-position: 7px 5px;
}
.trust-wallet-icon {
    background: url("./assets/images/trust_wallet.png");
    background-position: 6px 6px;
    background-size: 35px;
}
.btc-wallet-address button i:active {
    color: #1624595c;
}
.btc-wallet-address input::placeholder {
    color: $blue-color;
}
.btc-wallet-address input:disabled {
    background-color: $font-white-color;
}
input:focus {
    outline: none;
}
.fs-m {
    font-size: 16px;
}
.body-title {
    font-size: 18px;
}
.error-box {
    color: red;
}
.box-body {
    height: max-content;
    max-height: calc(100% - 71px);
    overflow: overlay;
    padding: 13px;
    border-radius: 0 0 25px 25px !important;
    border: 1px solid lightgray !important;
}

.loader {
    position: absolute;
    border: 4px solid #f3f3f3;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    left: 46%;
    top: 19%;
    z-index: 999;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.height-100 {
    height: 100%;
}

.d-none {
    display: none;
}
.border-none {
    border: none !important;
}
.flex-row {
    flex-direction: row !important;
}
.flex-row-reverse {
    flex-direction: row-reverse !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}
.flex-nowrap {
    flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.flex-justify-start {
    justify-content: flex-start !important;
}
.flex-justify-end {
    justify-content: flex-end !important;
}
.flex-justify-center {
    justify-content: center !important;
}
.flex-justify-between {
    justify-content: space-between !important;
}
.flex-justify-around {
    justify-content: space-around !important;
}

.flex-items-start {
    align-items: flex-start !important;
}
.flex-items-end {
    align-items: flex-end !important;
}
.flex-items-center {
    align-items: center !important;
}
.flex-items-baseline {
    align-items: baseline !important;
}
.flex-items-stretch {
    align-items: stretch !important;
}

.flex-content-start {
    align-content: flex-start !important;
}
.flex-content-end {
    align-content: flex-end !important;
}
.flex-content-center {
    align-content: center !important;
}
.flex-content-between {
    align-content: space-between !important;
}
.flex-content-around {
    align-content: space-around !important;
}
.flex-content-stretch {
    align-content: stretch !important;
}

.flex-1 {
    flex: 1 !important;
}
.flex-auto {
    flex: auto !important;
}
.flex-grow-0 {
    flex-grow: 0 !important;
}
.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-self-auto {
    align-self: auto !important;
}
.flex-self-start {
    align-self: flex-start !important;
}
.flex-self-end {
    align-self: flex-end !important;
}
.flex-self-center {
    align-self: center !important;
}
.flex-self-baseline {
    align-self: baseline !important;
}
.flex-self-stretch {
    align-self: stretch !important;
}

.flex-order-1 {
    order: 1 !important;
}
.flex-order-2 {
    order: 2 !important;
}
.flex-order-none {
    order: inherit !important;
}

$spacer: 8px !default;
$spacer-0: 0 !default; // 0
$spacer-1: $spacer * 0.5 !default; // 4px
$spacer-2: $spacer !default; // 8px
$spacer-3: $spacer * 2 !default; // 16px
$spacer-4: $spacer * 3 !default; // 24px
$spacer-5: $spacer * 4 !default; // 32px
$spacer-6: $spacer * 5 !default; // 40px

$spacer-7: $spacer * 6 !default; // 48px
$spacer-8: $spacer * 8 !default; // 64px
$spacer-9: $spacer * 10 !default; // 80px
$spacer-10: $spacer * 12 !default; // 96px
$spacer-11: $spacer * 14 !default; // 112px
$spacer-12: $spacer * 16 !default; // 128px

$spacers: (
    $spacer-0,
    $spacer-1,
    $spacer-2,
    $spacer-3,
    $spacer-4,
    $spacer-5,
    $spacer-6,
    $spacer-7,
    $spacer-8,
    $spacer-9,
    $spacer-10,
    $spacer-11,
    $spacer-12
) !default;

$spacer-map: (
    0: $spacer-0,
    1: $spacer-1,
    2: $spacer-2,
    3: $spacer-3,
    4: $spacer-4,
    5: $spacer-5,
    6: $spacer-6,
    7: $spacer-7,
    8: $spacer-8,
    9: $spacer-9,
    10: $spacer-10,
    11: $spacer-11,
    12: $spacer-12,
) !default;

$spacer-map-extended: (
    0: 0,
    1: $spacer-1,
    2: $spacer-2,
    3: $spacer-3,
    4: $spacer-4,
    5: $spacer-5,
    6: $spacer-6,
    7: $spacer-7,
    8: $spacer-8,
    9: $spacer-9,
    10: $spacer-10,
    11: $spacer-11,
    12: $spacer-12,
) !default;

@each $scale, $size in $spacer-map-extended {
    @if ($scale < length($spacer-map)) {
        .m-#{$scale} {
            margin: $size !important;
        }
    }

    .mt-#{$scale} {
        margin-top: $size !important;
    }
    .mb-#{$scale} {
        margin-bottom: $size !important;
    }

    @if ($scale < length($spacer-map)) {
        .mr-#{$scale} {
            margin-right: $size !important;
        }
        .ml-#{$scale} {
            margin-left: $size !important;
        }
    }

    @if ($size != 0) {
        .mt-n#{$scale} {
            margin-top: -$size !important;
        }
        .mb-n#{$scale} {
            margin-bottom: -$size !important;
        }

        @if ($scale < length($spacer-map)) {
            .mr-n#{$scale} {
                margin-right: -$size !important;
            }
            .ml-n#{$scale} {
                margin-left: -$size !important;
            }
        }
    }

    @if ($scale < length($spacer-map)) {
        .mx-#{$scale} {
            margin-right: $size !important;
            margin-left: $size !important;
        }
    }

    .my-#{$scale} {
        margin-top: $size !important;
        margin-bottom: $size !important;
    }
}
.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.m-auto {
    margin: auto !important;
}

.mt-auto {
    margin-top: auto !important;
}
.mr-auto {
    margin-right: auto !important;
}
.mb-auto {
    margin-bottom: auto !important;
}
.ml-auto {
    margin-left: auto !important;
}

$display-values: (
    block,
    flex,
    inline,
    inline-block,
    inline-flex,
    none,
    table,
    table-cell
) !default;
@each $display in $display-values {
    .d-#{$display} {
        display: $display !important;
    }
}

.v-hidden {
    visibility: hidden !important;
}
.v-visible {
    visibility: visible !important;
}

@each $scale, $size in $spacer-map-extended {
    @if ($scale < length($spacer-map)) {
        .p-#{$scale} {
            padding: $size !important;
        }
    }

    .pt-#{$scale} {
        padding-top: $size !important;
    }
    .pr-#{$scale} {
        padding-right: $size !important;
    }
    .pb-#{$scale} {
        padding-bottom: $size !important;
    }
    .pl-#{$scale} {
        padding-left: $size !important;
    }

    @if ($scale < length($spacer-map)) {
        .px-#{$scale} {
            padding-right: $size !important;
            padding-left: $size !important;
        }
    }

    .py-#{$scale} {
        padding-top: $size !important;
        padding-bottom: $size !important;
    }
}

.text-right {
    text-align: right !important;
}
.text-left {
    text-align: left !important;
}
.text-center {
    text-align: center !important;
}
$responsive-positions: (static, relative, absolute, fixed, sticky) !default;
@each $position in $responsive-positions {
    .position-#{$position} {
        position: $position !important;
    }
}
.top-0 {
    top: 0 !important;
}
.right-0 {
    right: 0 !important;
}
.bottom-0 {
    bottom: 0 !important;
}
.left-0 {
    left: 0 !important;
}
.top-auto {
    top: auto !important;
}
.right-auto {
    right: auto !important;
}
.bottom-auto {
    bottom: auto !important;
}
.left-auto {
    left: auto !important;
}
@each $overflow in (visible, hidden, auto, scroll) {
    .overflow-#{$overflow} {
        overflow: $overflow !important;
    }
    .overflow-x-#{$overflow} {
        overflow-x: $overflow !important;
    }
    .overflow-y-#{$overflow} {
        overflow-y: $overflow !important;
    }
}

.width-fit {
    max-width: 100% !important;
}
.width-full {
    width: 100% !important;
}
.height-fit {
    max-height: 100% !important;
}
.height-full {
    height: 100% !important;
}
@each $scale, $size in $spacer-map-extended {
    @if ($size != 0) {
        .top-#{$scale} {
            top: $size !important;
        }
        .right-#{$scale} {
            right: $size !important;
        }
        .bottom-#{$scale} {
            bottom: $size !important;
        }
        .left-#{$scale} {
            left: $size !important;
        }
    }

    @if ($size != 0) {
        .top-n#{$scale} {
            top: -$size !important;
        }
        .right-n#{$scale} {
            right: -$size !important;
        }
        .bottom-n#{$scale} {
            bottom: -$size !important;
        }
        .left-n#{$scale} {
            left: -$size !important;
        }
    }
}

.mat-new-style .mat-simple-snackbar-action {
    color: white;
}
.mat-new-style-success .mat-simple-snackbar-action {
    color: white;
}

.mat-new-style{
    background-color: red;
    color: white;
}
.mat-new-style-success{
    background-color: green;
    color: white;
}

:is(.box-loader):empty {
    position: relative;
    overflow: hidden;
}
.bg-color-green { background-color: #1eab4f !important}
.bg-blue-color {
    background-color: $background-blue-color;
}
.auction-result-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #ffffff;
    z-index: 2;
    line-height: 1.2;
}
.auction-result-overlay a {
    color: #ffffff;
}
.auction-result-overlay:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $blue-color;
    opacity: 0.8;
    z-index: -1;
}
.text-yellow-color {
    color: $yellow-color;
}
.text-size {
    font-size: 14px !important;
}

.fs-medium {
    font-size: 22px;
}
.rm-cursor {
    cursor: default;
}

.h-100 {
    height: 100%;
    color: #162459;
    border-radius: 50%;
    border: 1px solid #162459;
    background-color: #fff;
}
.full-height {
    position: absolute;
    top: 0;
}
.intro-screen-wrapper{height:100%;z-index: 99999999; overflow: hidden;}
.intro-screen-wrapper img{height:100%;width:101%;margin-inline:auto;animation:fade 1s ease 3.5s}
@media screen and (max-width: 480px){
    .intro-screen-wrapper{
        margin-top:0;
        margin-inline:auto;
        width:100vw;}}
@keyframes fade{0%{opacity:1}to{opacity:0}}

.font-size-15{
    font-size: 15px;
}

.profit-profile-table{
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-top: 13px;
    border-spacing: 0;
    border-collapse: collapse;
    border-right: 1px solid grey;
}
.profit-profile-table thead {
    background-color: #172459;
    padding: 5px;
    color: #fff;
    border: 1px solid #172459;
}
.profit-profile-table tr {
    border: 1px solid grey;
    border-top: 0px;
}
.profit-profile-table tr td {
    max-width: 95px;
    border-right: 1px solid grey;
}
.profit-class {
    font-weight: 600;
    color: green;
}
label{
    color: $blue-color}
.loss-class {
    font-weight: 600;
    color: red;
}
.play-icon{height: 43px;width: 43px}
.font-size-initial{
    font-size: initial;
}
.border-color-red{border-color: red;}
.text-red-color{color: red;}
.bg-red{background-color: red;}